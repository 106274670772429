/** @format */

import { Image } from '@/components/Image';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, ReactNode, useMemo } from 'react';
import { breakpointUpTo } from 'src/theme/mixins';
import theme from 'src/theme/styled-theme';
import { FindColor } from 'src/utils/findThemeColor';
import styled, { DefaultTheme } from 'styled-components';
import { StyledButton } from '../ui/Button';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { H1, H2, Kicker } from '../ui/Typography';

export const NewsArticleHeader: FC<Props> = ({
  heroBackgroundColor = 'teal.dark',
  type,
  category,
  title,
  image,
  mobileImage,
  date,
}) => {
  const { locale, asPath } = useRouter();
  const breadCrumbs = useBreadcrumbs();
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = !!(mediaQueryIndex !== null && mediaQueryIndex < 3);
  const formattedDate = useMemo(() => new Date(date).toLocaleDateString(locale ?? 'en'), []);

  const newsSlug = useMemo(() => {
    const arr = asPath.split('/');
    return arr[arr.length - 3];
  }, [asPath]);

  return (
    <ContainerWrapper $color={FindColor(heroBackgroundColor, theme.color)}>
      <ContainerRightSide>
        <Wrapper $type={type}>
          <WrapperLeftSide>
            {breadCrumbs.length > 0 && (
              <GoBackWrapper>
                <StyledButton
                  elementType="inbound"
                  variant={mobile ? 'yellow' : 'secondaryWhite'}
                  position="left"
                  href={newsSlug}
                >
                  News
                </StyledButton>
              </GoBackWrapper>
            )}
            <HeaderText>
              {typeof title === 'string' && <H1>{title}</H1>}
              <CustomStyleRichText>
                {typeof title !== 'string' && <RichText text={title} customOptions={titleOptions} />}
              </CustomStyleRichText>
            </HeaderText>
            <div>
              {<CategoryWrapper>{'#' + category}</CategoryWrapper>}
              <FooterInfo>{formattedDate}</FooterInfo>
            </div>
          </WrapperLeftSide>
          <HeaderImage $type={type}>
            <Image image={image} priority placeholder={false} />
            {mobile && mobileImage && <Image image={mobileImage} priority placeholder={false} />}
          </HeaderImage>
        </Wrapper>
      </ContainerRightSide>
    </ContainerWrapper>
  );
};

const titleOptions: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <H2 as="h1">{children}</H2>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <H2 as="h1">{children}</H2>;
    },
  },
};

type Props = {
  type?: string;
  category: string;
  title: string | Document;
  image: Asset;
  mobileImage?: Asset;
  date: string;
  heroBackgroundColor?: string;
};

const CategoryWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[1.125]};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3125rem;
  letter-spacing: 0.0275rem;
  padding-bottom: clamp(0.94rem, 0.88rem + 0.2667vw, 1.12rem);
  color: ${({ color, theme }) => color ?? theme.color.yellow};
`;

const GoBackWrapper = styled.div`
  padding-top: clamp(0.94rem, 0.4833rem + 2.0296vw, 2.31rem);
  --yellowColor: ${({ color, theme }) => theme.color.yellow};
  ${breakpointUpTo.lg`
    position: absolute;
    top:0;
  `}

  a {
    ${breakpointUpTo.lg`
      color: ${({ theme }: { theme: DefaultTheme }) => theme.color.yellow};
    `}
  }
`;

const WrapperLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: clamp(1.88rem, 1.1733rem + 3.1407vw, 4rem);
`;

export const FooterInfo = styled.span<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[1.125]};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3125rem;
  letter-spacing: 0.0275rem;
  color: ${({ color, theme }) => color ?? theme.color.white};
`;

const Wrapper = styled.section<{ $type?: string }>`
  display: grid;
  min-height: 34.377rem;
  max-width: 1680px;
  position: relative;
  grid-template-columns: minmax(30.5rem, 50%) minmax(34.75rem, 50%);

  ${breakpointUpTo.lg`
    min-height: 25rem;
    max-height: 100%;
    overflow:visible;
    & > div:nth-child(1){
        grid-row: 2/2;
        z-index:1;
    }
    grid-template-columns: 1fr;
    grid-template-rows: 14.6rem auto;
  `}

  color: ${({ theme }) => theme.color.white};
`;

const HeaderText = styled.div`
  z-index: 1;
  padding-top: clamp(6rem, 15.4933rem + -6.5926vw, 6.08rem);
  padding-bottom: clamp(0.94rem, -0.4133rem + 6.0148vw, 5rem);

  ${breakpointUpTo.lg`
    padding-top:1.55rem;
  `}
  h1,p {
    color: ${({ theme }) => theme.color.white};
    em {
      color: ${({ theme }) => theme.color.yellow};
    }
  }
  color: ${({ theme }) => theme.color.white};
`;

const CustomKicker = styled(Kicker)`
  position: relative;
  padding-bottom: 3.19rem;

  ${breakpointUpTo.lg`
    max-width: 8.4375rem;
    position: absolute;
    top: 1.3rem;
  `}

  &:after {
    content: '';
    margin-top: 0.75rem;
    display: block;
    width: 2.875rem;
    height: 0.10625rem;
    background-color: ${({ theme }) => theme.color.yellow};
  }
`;

const CustomStyleRichText = styled.div`
  color: ${({ theme }) => theme.color.white};
  h1 {
    max-width: 36.75rem;
    white-space: pre-line;
    ${breakpointUpTo.lg`
      padding-right: ${({ theme }: { theme: DefaultTheme }) => theme.container.smallPadding};
    `}
    ${breakpointUpTo.sm`
      white-space: normal;
    `}
    ${breakpointUpTo.lg`
      font-family: ${({ theme }: { theme: DefaultTheme }) => theme.font.title};
      font-size: ${({ theme }: { theme: DefaultTheme }) =>
        `clamp(${theme.font.size[1.5]}, 1.3702rem + 0.5769vw, ${theme.font.size[2.0625]})`};
      font-style: normal;
      font-weight: 400;
      line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
      letter-spacing: clamp(0.03rem, 0.0274rem + 0.0115vw, 0.04125rem);

      em {
    font-family: ${({ theme }: { theme: DefaultTheme }) => theme.font.accent};
    font-size: ${({ theme }: { theme: DefaultTheme }) =>
      `clamp(${theme.font.size[1.75]}, 1.6058rem + 0.6410vw, ${theme.font.size[2.375]})`};
    font-style: normal;
    font-weight: 700;
    line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
    color: ${({ keywordColor, theme }: { keywordColor: string; theme: DefaultTheme }) =>
      keywordColor ?? theme.color.black};
  }
    `}
  }
  p,
  h6 {
    max-width: 33.75rem;
    color: ${({ theme }) => theme.color.white};
    ${breakpointUpTo.lg`
    padding-right: ${({ theme }: { theme: DefaultTheme }) => theme.container.smallPadding};
    `}
  }
`;

const HeaderImage = styled.div<{ $type?: string }>`
  --padding: ${({ theme }) => theme.container.smallPadding};
  position: absolute;
  z-index: 0;
  right: 0;
  max-width: clamp(38rem, -33.5rem + 98vw, 52.25rem);
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  ${breakpointUpTo.lg`
  position: relative;
  left: calc(var(--padding) * -1);
  max-width:calc(100% + var(--padding));
  width:calc(100% + var(--padding));
  overflow:visible;
    > img:first-child:not(:only-child) {
    opacity: 0;
    z-index: -1;
    }
  `}

  ${breakpointUpTo.md`
   width: calc(100% + var(--padding)) !important;
    `}

  img {
    bottom: 0;
    right: 0;
    top: 0rem;
    transform: translate(0, 0);
    margin: 0;
    padding: 0;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${breakpointUpTo.md`
        right: 0;
        width: 100%!important;
        ${({ $type = '' }) => {
          return $type === 'work-with-us' && `object-position: top;`;
        }}
        top: 90%;
        transform: translate(0%,-90%);
        height:100% !important;
    `}
  }
`;
