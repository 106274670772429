/** @format */

import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from '../components/Breadcrumbs/BreadcrumbsLd';

export const useBreadcrumbs = () => {
  const { locale, asPath } = useRouter();

  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const currentUrl = useMemo(() => `${locale}/${asPath}`, [locale, asPath]);
  const [breadCrumbsItems, setBreadCrumbsItems] = useState<Breadcrumb[]>([]);

  const getItemUrl = (splittedUrl: string[], i: number) => {
    let itemUrl = `${origin}/`;
    for (let j = 0; j <= i; j++) {
      itemUrl += splittedUrl[j] + '/';
    }
    return itemUrl;
  };

  useEffect(() => {
    let splittedUrl = currentUrl.split('/').filter(n => n);
    const isBlogPath = splittedUrl.includes('news');

    const list: Breadcrumb[] = splittedUrl.map((item, i) => {
      const itemUrl = getItemUrl(splittedUrl, i);
      const itemPath = `/${itemUrl
        .split('/')
        .filter(n => n)
        .splice(3)
        .join('/')}`;

      if (isBlogPath) {
        return {
          position: i + 1,
          url: itemUrl,
          path: itemPath,
          active: i === splittedUrl.length - 1,
        };
      } else {
        return {
          position: (i > 1 ? i - 1 : i) + 1,
          url: itemUrl,
          active: i === splittedUrl.length - 1,
          path: itemPath,
        };
      }
    });

    setBreadCrumbsItems([...list]);

    if (!isBlogPath && list.length === 3) {
      const filteredBreadCrumbs = list.filter((el, i) => i !== 1);
      setBreadCrumbsItems([...filteredBreadCrumbs]);
    }
  }, []);

  return breadCrumbsItems;
};
