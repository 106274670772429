/** @format */

import { NewsArticleHeader } from '@/components/Blog/NewsArticleHeader';
import { NewsRelatedArticle } from '@/components/Blog/NewsRelatedArticles';
import { PageWrapper } from '@/components/PageWrapper';
import { Container } from '@/components/ui/Layout';
import { RichText } from '@/components/ui/RichText';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { ArticlePageProps, CommonPageProps } from '@/types/pageprops';
import { Locale } from '@/types/shared';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

export const Article: FC<Props> = ({
  page,
  navigation,
  footer,
  company,
  translations,
  categoryBlogPosts,
  newsSlugs,
}) => {
  const { asPath } = useRouter();
  const { setSlugs } = useLanguageSwitcherContext();

  const completeSlug = asPath
    .split('/')
    .filter((_, i) => i > 1)
    .join('/');

  const calcSlug = useCallback(
    (lang: Locale) => (lang === page.language ? `${completeSlug}` : `${newsSlugs[lang]}/`),
    [],
  );

  useEffect(() => {
    const newsSlugs = {
      en: calcSlug('en'),
      it: calcSlug('it'),
      es: calcSlug('es'),
      fr: calcSlug('fr'),
      pt: calcSlug('pt'),
    };

    setSlugs(newsSlugs);
  }, []);

  return (
    <PageWrapper
      meta={page.metadata.fields}
      pageType="news"
      navigation={navigation}
      footer={footer}
      company={company}
      translations={translations}
      slug={asPath}
    >
      <NewsArticleHeader image={page.headerImage} title={page.title} date={page.date} category={page.category} />
      <CustomContainer>
        <RichText text={page.post} />
      </CustomContainer>
      {page.category && categoryBlogPosts.length > 0 && (
        <NewsRelatedArticle articles={categoryBlogPosts} category={page.category} />
      )}
    </PageWrapper>
  );
};

type Props = CommonPageProps & ArticlePageProps;

export const CustomContainer = styled(Container)`
  max-width: 885px;
  margin-block: clamp(3.76rem, 3.2rem + 2.4889vw, 5.44rem);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.color.teal.dark};
  }
  h1,
  h2,
  h3 {
    margin-top: clamp(3.75rem, 3.54rem + 0.9333vw, 4.38rem);
    margin-bottom: clamp(0.94rem, 0.5233rem + 1.8519vw, 2.19rem);
  }
  h4,
  h5,
  h6 {
    margin-block: clamp(1.81rem, 1.81rem + 0vw, 1.81rem);
  }
  p {
    margin-bottom: 0.75rem;
  }
  em {
    color: ${({ theme }) => theme.color.teal.light};
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
