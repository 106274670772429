/** @format */

import { i18nContext } from '@/context/i18nContext';
import { FunctionComponent as FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Mask from '../../assets/mask-scalini.svg';
import { breakpointUpTo } from '../../theme/mixins';
import { BlogPostCard } from '../../types/news';
import { Icon } from '../ui/Icon';
import { Container, ContainerWrapper } from '../ui/Layout';
import { H3 } from '../ui/Typography';
import { Card } from './Card';

export const NewsRelatedArticle: FC<Props> = ({ articles, category }) => {
  const theme = useContext(ThemeContext);

  return (
    <i18nContext.Consumer>
      {t => (
        <ContainerWrapper $color={theme!.color.white}>
          <CustomContainer color={theme!.color.white}>
            <Wrapper>
              <TitleWrapper>
                <H3 as="h2">
                  {t.common.speaking_of} <em>#{category}</em>
                </H3>
              </TitleWrapper>
              <CardsWrapper>
                {articles.map((article, index) => (
                  <Card key={index} post={article} />
                ))}
              </CardsWrapper>
              <MaskSVG icon={Mask} />
            </Wrapper>
          </CustomContainer>
        </ContainerWrapper>
      )}
    </i18nContext.Consumer>
  );
};

const CustomContainer = styled(Container)`
  ${breakpointUpTo.md`
    width: 100%;
  `}
  padding-top:2.5rem;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  overscroll-behavior-x: none;
  background-color: ${({ theme }) => theme.color.pink};
  border-radius: clamp(2.3125rem, 1.625rem + 3.0556vw, 4.375rem);
`;

const CardsWrapper = styled.div`
  --padding: ${({ theme }) => theme.container.smallPadding};
  position: relative;
  z-index: 1;
  padding-bottom: clamp(3.75rem, 3.1667rem + 2.5926vw, 5.5rem);
  padding-inline: var(--padding);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(16rem, 16rem), 27rem));
  grid-gap: 1.31rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  a {
    height: 100%;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  padding-block: clamp(3.75rem, 3.1667rem + 2.5926vw, 5.5rem) clamp(1.88rem, 1.1733rem + 3.1407vw, 4rem);
  h2 {
    color: ${({ theme }) => theme.color.white};
    em {
      color: ${({ theme }) => theme.color.yellow};
    }
  }
`;

const MaskSVG = styled(Icon)`
  position: absolute;
  z-index: 0;
  bottom: -4%;
  left: -30%;
  transform: translateX(30%) rotate(-11.25deg);
  background-size: contain !important;
  background-repeat: no-repeat;
  width: clamp(32rem, 26rem + 26.6667vw, 50rem);
  height: clamp(32rem, 26rem + 26.6667vw, 50rem);

  ${breakpointUpTo.md`
    left: -50%;
  `}
`;

type Props = {
  articles: BlogPostCard[];
  category: string;
};
