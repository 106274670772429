/** @format */

import { i18nContext } from '@/context/i18nContext';
import { GTMArticleClick } from '@/lib/google-tag-manager';
import { BlogPostCard } from '@/types/news';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, ReactNode, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { breakpointFrom } from '../../theme/mixins';
import { getGTMLabel } from '../../utils/getGTMLabel';
import { Image } from '../Image';
import { InboundLink } from '../ui/Link';
import { RichText } from '../ui/RichText';
import { FooterInfo, H4, P } from '../ui/Typography';

export const Card: FC<Props> = ({ post }) => {
  const { locale, asPath } = useRouter();
  const t = useContext(i18nContext);
  const formattedDate = useMemo(() => new Date(post.date).toLocaleDateString(locale ?? 'en'), [post]);

  const newsSlug = useMemo(() => {
    const arr = asPath.split('/');
    return arr[2];
  }, [asPath]);

  return (
    <Container
      onClick={() => GTMArticleClick(getGTMLabel(documentToPlainTextString(post.title)), post.category.toLowerCase())}
      href={`${newsSlug}/${post.slug.fields.slug}`}
      locale={locale}
    >
      <ImageWrapper>
        <Image image={post.headerImage} thumb={true} />
      </ImageWrapper>
      <P as="span" className="category">
        {t.news[post.category.toLowerCase().replace(' ', '-')]}
      </P>
      <div>
        <FooterInfo>{formattedDate}</FooterInfo>
        <RichText text={post.title} customOptions={titleOptions} />
        <RichText text={post.extract} />
      </div>
    </Container>
  );
};
type Props = {
  post: BlogPostCard;
};

const Container = styled(InboundLink)`
  display: grid;
  grid-template-rows: 12rem 1fr;
  align-items: start;
  justify-content: start;

  position: relative;
  /* max-width: 27rem; */
  min-height: 25rem;
  border-radius: 0.94rem;
  overflow: hidden;

  background: ${({ theme }) => theme.color.teal.dark};

  h2,
  p,
  span {
    color: ${({ theme }) => theme.color.white};
  }

  h2 > em {
    color: ${({ theme }) => theme.color.yellow};
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  & > :not(img):not(.category) {
    padding-inline: clamp(0.94rem, 0.8033rem + 0.6074vw, 1.35rem);
    padding-block: clamp(0.94rem, 0.5467rem + 1.7481vw, 2.12rem);

    & > * + * {
      margin-top: 0.62rem;
    }
  }

  .category {
    --category-font-size: ${({ theme }) =>
      `clamp(${theme.font.size[1]}, 0.9583rem + 0.1852vw, ${theme.font.size[1.125]})`};
    --category-padding: 0.25rem;
    grid-row: 1;
    grid-column: 1;

    position: absolute;
    top: 12rem;
    transform: translateY(-100%);

    ${breakpointFrom.md`
        top: 1.94rem;
        transform: translateY(0);
    `}

    width: fit-content;
    padding-inline: 1.25rem 0.62rem;
    padding-block: var(--category-padding);

    background: ${({ theme }) => theme.color.yellow};
    color: ${({ theme }) => theme.color.teal.dark};
    text-transform: uppercase;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 !important;

  img {
    display: block;
    // I don't know... With just width: 100% or width: auto it doesn't always work
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
    padding-inline: 0;
    padding-block: 0;
    grid-row: 1;
    grid-column: 1;
  }
`;

const titleOptions: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <H4 as="h2">{children}</H4>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <H4 as="h2">{children}</H4>;
    },
  },
};
